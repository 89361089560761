export default {
  translation: {
    common: {
      delete: 'Удалить',
      deleteModalTitle: 'Вы уверены, что хотите удалить этот элемент?',
      ok: 'Да',
      cancel: 'Нет',
      total: 'Итого',
      rename: 'Переименовать',
      name: 'Наименование',
      save: 'Сохранить',
      namePlaceholder: 'Пожалуйста, введите имя',
      next: 'Далее',
      create: 'Создать',
      edit: 'Редактировать',
      upload: 'Загрузить',
      english: 'Английский',
      chinese: 'Упрощенный китайский',
      traditionalChinese: 'Традиционный китайский',
      language: 'Язык',
      languageMessage: 'Пожалуйста, выберите ваш язык!',
      languagePlaceholder: 'выберите ваш язык',
      copy: 'Копировать',
      copied: 'Скопировано',
      comingSoon: 'Скоро будет',
      download: 'Скачать',
      close: 'Закрыть',
      preview: 'Предварительный просмотр',
      move: 'Переместить',
      warn: 'Примечание',
      russian: 'Русский',
      action: 'Действие',
      pleaseSelect: 'Пожалуйста, выберите',
      pleaseInput: 'Пожалуйста введите',
      submit: 'Отправить',
      template_title_blank: 'Пустой',
      template_text_blank: 'Создайте вашего агента с нуля',
      template_title_0: 'Веб-помощник для поиска',
      template_text_0:
        'Шаблон чат-ассистента, который объединяет информацию из базы знаний и веб-поиска для ответов на запросы. Давайте начнем с настройки вашей базы знаний в разделе "Извлечение"!',
      template_title_1: 'Чат-бот общего назначения',
      template_text_1:
        'Чат-бот для общих задач, включающий области здравоохранения, финансов, эмоционального общения, прогноза погоды в реальном времени и предоставления информации.',
      template_title_2: 'HR-ассистент для подбора кандидатов',
      template_text_2:
        'Агент для презентации кандидатов, описания вакансий, ответов на вопросы и запроса контактных данных кандидатов. Давайте начнем с подключения базы знаний…',
      template_title_3: 'Служба поддержки клиентов',
      template_text_3:
        'Чат-бот для обслуживания клиентов, который объясняет характеристики продукта, отвечает на вопросы и снижает негативные эмоции.',
      template_title_4: 'Переводчик',
      template_text_4:
        'Агент для перевода, основанный на рефлексивном агентном рабочем процессе, вдохновлённый проектом Эндрю Ынга: https://github.com/andrewyng/translation-agent. 1. Запросите LLM перевести текст на целевой язык. 2. Попросите LLM проанализировать перевод и предложить конструктивные улучшения. 3. Используйте эти предложения для улучшения перевода.',
      template_title_5: 'Text To SQL',
      template_text_5:
        ' Агент, который преобразует пользовательские запросы в SQL-запросы. Вам необходимо подготовить три базы знаний: 1. DDL для вашей базы данных; 2. Примеры пользовательских запросов, преобразованных в SQL-запросы; 3. Полное описание вашей базы данных, включая, но не ограничиваясь, таблицы и записи.',
      template_title_6: 'Ассистент БД',
      template_text_6:
        'Продвинутый агент, который преобразует пользовательские запросы в SQL-запросы, выполняет их и оценивает результаты, возвращая их пользователю. Вам необходимо подготовить три базы знаний: 1. DDL для вашей базы данных; 2. Примеры пользовательских запросов, преобразованных в SQL-запросы; 3. Полное описание вашей базы данных, включая, но не ограничиваясь, таблицы и записи. Также требуется настроить соответствующую базу данных.',
      template_title_7: 'Медицинский консультант',
      template_text_7:
        'Консультант, который предлагает медицинские рекомендации на основе внутреннего QA-набора данных и результатов поиска PubMed. Обратите внимание, что ответы этого агента предназначены только для справки и могут быть неточными. Набор данных доступен по ссылке: InfiniFlow/medical_QA.',
      template_title_8: 'Интеллектуальный инвестиционный советник',
      template_text_8:
        'Интеллектуальный финансовый помощник, который отвечает на ваши вопросы, используя данные о финансовом рынке в реальном времени.',
      template_title_9: 'Генератор SEO-блогов',
      template_text_9:
        'Генератор блогов, создающий SEO-оптимизированный контент на основе выбранного заголовка или ключевых слов.',
    },
    login: {
      login: 'Войти',
      signUp: 'Зарегистрироваться',
      loginDescription: 'Мы рады видеть вас снова!',
      registerDescription: 'Рады видеть вас в нашем сообществе!',
      emailLabel: 'Электронная почта',
      emailPlaceholder: 'Пожалуйста, введите электронную почту',
      passwordLabel: 'Пароль',
      passwordPlaceholder: 'Пожалуйста, введите пароль',
      rememberMe: 'Запомнить меня',
      signInTip: 'Нет учетной записи?',
      signUpTip: 'Уже есть учетная запись?',
      nicknameLabel: 'Никнейм',
      nicknamePlaceholder: 'Пожалуйста, введите никнейм',
      register: 'Создать учетную запись',
      continue: 'Продолжить',
      title: 'Начните создавать ваших умных помощников.',
      description:
        'Зарегистрируйтесь бесплатно, чтобы исследовать передовые технологии RAG. Создавайте базы знаний и ИИ, чтобы укрепить ваш бизнес.',
      review: 'на основе более 500 отзывов',
    },
    header: {
      knowledgeBase: 'База знаний',
      chat: 'Чат',
      register: 'Регистрация',
      signin: 'Вход',
      home: 'Главная',
      setting: 'Настройки пользователя',
      logout: 'Выход',
      fileManager: 'Управление файлами',
      flow: 'Агент',
      search: 'Поиск',
    },
    knowledgeList: {
      welcome: 'С возвращением',
      description: 'Какую базу знаний мы будем использовать сегодня?',
      createKnowledgeBase: 'Создать базу знаний',
      name: 'Наименование',
      namePlaceholder: 'Пожалуйста, введите имя!',
      doc: 'Документы',
      searchKnowledgePlaceholder: 'Поиск',
      noMoreData: 'It is all, nothing more',
    },
    knowledgeDetails: {
      dataset: 'Документы',
      testing: 'Проверка извлечения',
      files: 'файлы',
      configuration: 'Конфигурация',
      name: 'Наименование',
      namePlaceholder: 'Пожалуйста, введите имя!',
      doc: 'Документы',
      datasetDescription:
        'Вопросы и ответы могут быть предоставлены только после успешного парсинга.',
      addFile: 'Добавить файлы',
      searchFiles: 'Поиск по вашим файлам',
      localFiles: 'Локальные файлы',
      emptyFiles: 'Создать пустой файл',
      webCrawl: 'Веб-краулинг',
      chunkNumber: 'Количество чанков',
      uploadDate: 'Дата загрузки',
      chunkMethod: 'Метод чанка',
      enabled: 'Разрешить',
      disabled: 'Выключено',
      action: 'Действие',
      parsingStatus: 'Статус парсинга',
      processBeginAt: 'Начало процесса в',
      processDuration: 'Длительность процесса',
      progressMsg: 'Сообщение о прогрессе',
      testingDescription:
        'Проведите проверку процесса извлечения, чтобы убедиться, что RAGFlow может извлечь необходимый контент для LLM.',
      similarityThreshold: 'Порог сходства',
      similarityThresholdTip:
        'Мы используем гибридный показатель схожести для оценки расстояния между двумя строками текста. Это взвешенная схожесть по ключевым словам и косинусная схожесть вектора. Если схожесть между запросом и чанком меньше заданного порога, чанк будет исключён.',
      vectorSimilarityWeight: 'Вес сходства ключевых слов',
      vectorSimilarityWeightTip:
        'Мы используем гибридный показатель схожести для оценки расстояния между двумя строками текста. Это взвешенная схожесть по ключевым словам и косинусная схожесть вектора или переоценка (0~1). Сумма обоих весов равна 1.0.',
      testText: 'Тестовый текст',
      testTextPlaceholder: 'Пожалуйста, введите ваш вопрос!',
      testingLabel: 'Тестирование',
      similarity: 'Гибридная схожесть',
      termSimilarity: 'Схожесть по терминам',
      vectorSimilarity: 'Векторная схожесть',
      hits: 'Попадания',
      view: 'Просмотр',
      filesSelected: 'Выбранные файлы',
      upload: 'Загрузить',
      run: 'Запустить',
      runningStatus0: 'НЕ НАЧАТО',
      runningStatus1: 'Парсинг',
      runningStatus2: 'ОТМЕНЕНО',
      runningStatus3: 'УСПЕШНО',
      runningStatus4: 'НЕУДАЧА',
      pageRanges: 'Диапазоны страниц',
      pageRangesTip:
        'диапазоны страниц: Определите диапазоны страниц, которые нужно распарсить. Страницы, не включенные в эти диапазоны, будут игнорироваться.',
      fromPlaceholder: 'от',
      fromMessage: 'Отсутствует номер начальной страницы',
      toPlaceholder: 'до',
      toMessage: 'Отсутствует номер конечной страницы (исключительно)',
      layoutRecognize: 'Распознавание структуры документа',
      layoutRecognizeTip:
        'Использование визуальных моделей для анализа структуры документа, чтобы лучше определить структуру документа, найти места для заголовков, текстовых блоков, изображений и таблиц. Без этой функции можно будет получить только обычный текст PDF.',
      taskPageSize: 'Размер страницы задачи',
      taskPageSizeMessage: 'Пожалуйста, введите размер страницы задачи!',
      taskPageSizeTip: `Если используется распознавание макета, PDF-файл будет разделен на группы последовательных страниц. Анализ макета будет выполняться параллельно между группами для увеличения скорости обработки. 'Размер страницы задачи' определяет размер групп. Чем больше размер страницы, тем меньше вероятность разделения непрерывного текста между страницами на разные чанки.`,
      addPage: 'Добавить страницу',
      greaterThan: 'Текущее значение должно быть больше чем!',
      greaterThanPrevious: 'Текущее значение должно быть больше предыдущего!',
      selectFiles: 'Выбрать файлы',
      changeSpecificCategory: 'Изменить конкретную категорию',
      uploadTitle: 'Нажмите или перетащите файл в эту область для загрузки',
      uploadDescription:
        'Поддержка загрузки одного или нескольких файлов. Строго запрещено загружать корпоративные данные или другие запрещенные файлы.',
      chunk: 'Чанк',
      bulk: 'Массовый режим',
      cancel: 'Отмена',
      rerankModel: 'Модель переоценки',
      rerankPlaceholder: 'Пожалуйста, выберите',
      rerankTip: `Если оставить пустым, RAGFlow будет использовать комбинацию взвешенной схожести по ключевым словам и взвешенной косинусной схожести вектора; если выбран модель переоценки, взвешенный показатель переоценки заменит взвешенную косинусную схожесть вектора.`,
      topK: 'Топ-K',
      topKTip: `K чанков будет передано в модели переоценки.`,
      delimiter: `Разделители`,
      html4excel: 'Excel в HTML',
      html4excelTip: `Будет ли Excel преобразован в таблицу HTML. Если значение "ЛОЖЬ", каждая строка в Excel будет преобразована в чанк.`,
      autoKeywords: 'Авто-ключевые слова',
      autoKeywordsTip: `Извлекайте N ключевых слов для каждого блока, чтобы повысить их рейтинг по запросам, содержащим эти слова. Вы можете проверить или обновить добавленные ключевые слова для блока в списке блоков. Учтите, что для этого будут использованы дополнительные токены, предусмотренные моделью в настройках системы.`,
      autoQuestions: 'Авто-вопросы',
      autoQuestionsTip: `Извлекайте N вопросов для каждого блока, чтобы повысить их рейтинг по запросам, содержащим эти вопросы. Вы можете проверить или обновить добавленные вопросы для блока в списке блоков. Эта функция не нарушит процесс разбиения на блоки в случае ошибки, за исключением добавления пустого результата в исходный блок. Учтите, что для этого будут использованы дополнительные токены, предусмотренные моделью в настройках системы.`,
    },
    knowledgeConfiguration: {
      titleDescription:
        'Обновите данные вашей базы знаний, особенно метод парсинга.',
      name: 'Название базы знаний',
      photo: 'Фото базы знаний',
      description: 'Описание',
      language: 'Язык',
      languageMessage: 'Пожалуйста, введите ваш язык!',
      languagePlaceholder: 'Пожалуйста, введите ваш язык!',
      permissions: 'Права доступа',
      embeddingModel: 'Модель эмбеддинга',
      chunkTokenNumber: 'Количество токенов в чанке',
      chunkTokenNumberMessage: 'Количество токенов в фрагменте обязательно',
      embeddingModelTip:
        'Модель эмбеддинга, используемая для эмбеддинга чанков. Она не изменяема, как только в базе знаний есть чанки. Для изменения модели необходимо удалить все чанки',
      permissionsTip:
        'Если права доступа установлены на "Команда", все члены команды могут редактировать базу знаний.',
      chunkTokenNumberTip:
        'Это определяет примерное количество токенов в одном чанке.',
      chunkMethod: 'Метод чанка',
      chunkMethodTip: 'Инструкции находятся с правой стороны этого интерфейса.',
      upload: 'Загрузить',
      english: 'Английский',
      russian: 'Русский',
      chinese: 'Китайский',
      embeddingModelPlaceholder: 'Пожалуйста, выберите модель встраивания',
      chunkMethodPlaceholder: 'Пожалуйста, выберите метод разбивки',
      save: 'Сохранить',
      me: 'Только я',
      team: 'Команда',
      cancel: 'Отмена',
      methodTitle: 'Описание метода чанков',
      methodExamples: 'Примеры',
      methodExamplesDescription:
        'Для облегчения понимания приведены следующие скриншоты.',
      dialogueExamplesTitle: 'Примеры диалога для метода',
      methodEmpty: 'Это отобразит визуальное объяснение категорий базы знаний',
      book: `<p>Поддерживаемые форматы файлов: <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Для каждой книги в формате PDF необходимо задать <i>диапазоны страниц</i> чтобы убрать ненужную информацию и сократить время анализа.</p>`,
      laws: `<p>Поддерживаемые форматы файлов: <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.</p><p>
      Юридические документы обычно следуют строгому формату написания. Мы используем текстовые признаки для определения точек разделения.
      </p><p>
      Размер чанков будет соответствовать гранулярности уровня "СТАТЬЯ", при этом весь текст более высокого уровня включается в чанк.
      </p>`,
      manual: `<p>Поддерживается только формат <b>PDF</b>.</p><p>
      Предполагается, что руководство имеет иерархическую структуру разделов, и заголовки самых нижних уровней используются как базовая единица для разделения документа на чанки. Таким образом, рисунки и таблицы в пределах одного раздела не будут разделяться, что может привести к увеличению размера чанков.
      </p>`,
      naive: `<p>Поддерживаемые форматы файлов: <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML, HTML</b>.</p>
      <p>Этот метод использует наивные способы разбиения на чанки файлов: </p>
      <p>
      <li>Последовательный текст будет нарезан на части с использованием моделей для распознавания текста.</li>
      <li>Затем эти части будут объединены на чанки, количество токенов в которых не превышает 'Количество токенов'.</li></p>`,
      paper: `<p>Поддерживается только формат <b>PDF</b>.</p><p>
      Научные статьи будут разделены по разделам, <i>таким как аннотация, 1.1, 1.2</i> и т.д.</p><p>
      Такой подход позволяет LLM эффективнее суммировать статью и предоставлять более полные и понятные ответы. Однако он увеличивает контекст для общения AI и повышает вычислительные затраты для LLM. Поэтому во время диалога рекомендуется снизить значение ‘<b>topN</b>’.</p>`,
      presentation: `<p>Поддерживаемые форматы файлов: <b>PDF</b>, <b>PPTX</b>.</p><p>
      Каждая страница будет рассматриваться как фрагмент. Эскиз каждой страницы будет сохранен.</p><p>
      <i>Все загруженные вами PPT-файлы будут автоматически разбиты на фрагменты с использованием этого метода, настройка для каждого PPT-файла не требуется.</i></p>`,
      qa: `
      <p>
      Данный метод чанков поддерживает форматы файлов <b>EXCEL</b> и <b>CSV/TXT</b>.
    </p>
    <li>
      Если файл представлен в формате <b>Excel</b>, он должен содержать две колонки без заголовков: одна для вопросов, другая для ответов, где колонка с вопросами предшествует колонке с ответами. Допускается использование нескольких листов, при условии корректной структуры колонок.
    </li>
    <li>
      Если файл представлен в формате <b>CSV/TXT</b>, он должен быть закодирован в UTF-8 с использованием ТАБ как разделителя для отделения вопросов и ответов.
    </li>
    <p>
      <i>
        Строки текста, которые не соответствуют вышеуказанным правилам, будут проигнорированы, а каждая пара "вопрос-ответ" будет рассматриваться как отдельный чанк.
      </i>
    </p>
      `,
      resume: `<p>Поддерживаемые форматы файлов: <b>DOCX</b>, <b>PDF</b>, <b>TXT</b>.
      </p><p>
      Резюме различных форматов анализируются и организуются в структурированные данные для облегчения поиска кандидатов рекрутерами.
      </p><p>
      Вместо разбиения резюме, мы парсим резюме в структурированные данные. Как HR, вы можете загрузить все резюме, 
      а затем вы можете перечислить всех кандидатов, соответствующих требованиям, просто пообщавшись с <i>'RAGFlow'</i>.
      </p>
      `,
      table: `<p>Поддерживаемые форматы файлов: <b>EXCEL</b> и <b>CSV/TXT</b></p><p>
      Предварительные условия и рекомендации:
      <ul>
    <li>Для файлов CSV или TXT разделителем между колонками должен быть <em><b>TAB</b></em>.</li>
    <li>Первая строка должна содержать заголовки колонок.</li>
    <li>Заголовки колонок должны быть осмысленными терминами, чтобы помочь LLM понять содержание. Рекомендуется использовать синонимы, разделённые слэшем <i>'/'</i>,  и перечислять значения в скобках. Например: <i>'Пол/Гендер (мужской, женский)'</i>.<p>
   Пример заголовков колонок: <ol>
        <li>поставщик/поставщик <b>'TAB' </b>Цвет (Жёлтый, Синий, Коричневый) <b>'TAB' </b>Пол/Гендер (мужской, женский) <b>'TAB' </b>размер (M, L, XL, XXL)</li>
        </ol>
        </p>
    </li>
    <li>Каждая строка таблицы будет рассматриваться как отдельный чанк.</li>
    </ul>`,
      picture: `
    <p>Поддерживаются файлы изображений. Видео скоро появится.</p><p>
    Если на картинке есть текст, применяется OCR для извлечения текста в качестве текстового описания.
    </p><p>
    Если извлеченного OCR текста недостаточно, используется визуальный LLM для получения описаний.
    </p>`,
      one: `
    <p>Поддерживаемые форматы файлов: <b>DOCX, EXCEL, PDF, TXT</b>.
    </p><p>
    Для документа он будет рассматриваться как целый фрагмент, без разбиения.
    </p><p>
    Если вы хотите обобщить что-то, что требует всего контекста статьи, и длина контекста выбранного LLM охватывает длину документа, вы можете попробовать этот метод.
    </p>`,
      knowledgeGraph: `<p>Поддерживаемые форматы файлов: <b>DOCX, EXCEL, PPT, IMAGE, PDF, TXT, MD, JSON, EML</b>

<p>После разбиения файлов, используются фрагменты для извлечения графа знаний и умственной карты всего документа. Этот метод применяет наивные способы разбиения файлов:
Последовательный текст будет разбит на кусочки, каждый из которых состоит примерно из 512 токенов.</p>
<p>Затем фрагменты будут переданы LLM для извлечения узлов и связей графа знаний и умственной карты.</p>

Обратите внимание на тип сущности, который нужно указать.</p>`,
      useRaptor: 'Использовать RAPTOR для улучшения извлечения',
      useRaptorTip:
        'Рекурсивная абстрактивная обработка для древовидного поиска. Пожалуйста, обратитесь к https://huggingface.co/papers/2401.18059',
      prompt: 'Запрос',
      promptTip: 'Запрос LLM, используемый для суммаризации.',
      promptMessage: 'Запрос обязателен.',
      promptText: `Пожалуйста, резюмируйте следующие абзацы. Будьте осторожны с цифрами, не придумывайте ничего лишнего. Абзацы следующие:
      {cluster_content}
Приведенное выше содержание необходимо обобщить.`,
      maxToken: 'Максимальное количество токенов',
      maxTokenTip: 'Максимальное количество токенов для обобщения.',
      maxTokenMessage: 'Требуется указать максимальное количество токенов.',
      threshold: 'Пороговое значение',
      thresholdTip: 'Чем выше пороговое значение, тем меньше будет кластеров.',
      thresholdMessage: 'Требуется указать порог.',
      maxCluster: 'Максимальное количество кластеров',
      maxClusterTip: 'Максимальное количество кластеров.',
      maxClusterMessage: 'Требуется указать максимальное количество кластеров.',
      randomSeed: 'Случайный параметр',
      randomSeedMessage: 'Требуется указать случайный параметр',
      entityTypes: 'Типы сущностей',
    },
    chunk: {
      chunk: 'Чанк',
      bulk: 'Массовый режим',
      selectAll: 'Выбрать всё',
      enabledSelected: 'Включить выделенные',
      disabledSelected: 'Отключить выделенные',
      deleteSelected: 'Удалить выделенные',
      search: 'Поиск',
      all: 'Все',
      enabled: 'Включено',
      disabled: 'Отключено',
      keyword: 'Ключевое слово',
      function: 'Функция',
      chunkMessage: 'Пожалуйста, введите значение!',
      full: 'Полный текст',
      ellipse: 'Эллипс',
      graph: 'Знаниевую граф',
      mind: 'Ментальная карта',
    },
    chat: {
      newConversation: 'Новый разговор',
      createAssistant: 'Создать ассистента',
      assistantSetting: 'Настройка Ассистента',
      promptEngine: 'Настройка запроса',
      modelSetting: 'Настройка модели',
      chat: 'Чат',
      newChat: 'Новый чат',
      send: 'Отправить',
      sendPlaceholder: 'Сообщение ассистенту...',
      chatConfiguration: 'Настройки чата',
      chatConfigurationDescription:
        'Здесь создайте специального ассистента для ваших знаний!',
      assistantName: 'Наименование Ассистента',
      assistantNameMessage: 'Имя ассистента обязательно',
      namePlaceholder: 'например, Резюме Джарвиса',
      assistantAvatar: 'Аватар',
      language: 'Язык',
      emptyResponse: 'Пустой ответ',
      emptyResponseTip: `Если ничего не найдено в базе знаний по запросу пользователя, это будет использовано как ответ. Если вы хотите, чтобы LLM сформулировал собственный ответ, оставьте это поле пустым.`,
      setAnOpener: 'Установите приветствие',
      setAnOpenerInitial: `Привет! Я ваш помощник, чем могу помочь?`,
      setAnOpenerTip:
        'Какое приветствие должно быть показано пользователям при первом обращении?',
      knowledgeBases: 'Базы знаний',
      knowledgeBasesMessage: 'Пожалуйста, выберите',
      knowledgeBasesTip: 'Выберите связанные базы знаний.',
      system: 'Системная инструкция',
      systemInitialValue: `Вы - умный ассистент. Пожалуйста, подведите итоги содержания базы знаний для ответа на вопрос. Пожалуйста, перечислите данные в базе знаний и ответьте подробно. Когда все содержимое базы знаний не имеет отношения к вопросу, ваш ответ должен содержать предложение "Ответ, который вы ищете, не найден в базе знаний!" Ответы должны учитывать историю чата.
      Вот база знаний:
      {knowledge}
      Выше представлена база знаний.`,
      systemMessage: 'Пожалуйста, введите!',
      systemTip:
        'Инструкции, которым нужно следовать LLM при ответах на вопросы, такие как длина ответа, язык ответа и другие.',
      topN: 'Топ N',
      topNTip: `Не все чанки, чья схожесть выше порога "сходства", будут переданы LLM. LLM сможет видеть только эти "Топ N" чанков.`,
      variable: 'Переменная',
      variableTip: `Переменные могут помочь вам общаться с пользователями, используя различные стратегии, если вы используете API для диалога. Переменные используются для заполнения части "Системная инструкция" в подсказке, чтобы дать LLM подсказку. Переменная 'knowledge' является очень специальной и будет заполнена извлечёнными чанками. Все переменные в части "Системная инструкция" должны быть заключены в фигурные скобки.`,
      add: 'Добавить',
      key: 'Ключ',
      optional: 'Необязательно',
      operation: 'Операция',
      model: 'Модель',
      modelTip: 'Большая языковая модель для чата',
      modelMessage: 'Пожалуйста, выберите!',
      freedom: 'Адаптация',
      improvise: 'Импровизированный',
      precise: 'Точный',
      balance: 'В балансе',
      freedomTip: `"Точный" означает, что модель будет осторожной и ответит на ваш вопрос сдержанно.
"Импровизированнй" означает, что вы хотите, чтобы модель говорила более развёрнуто и свободно.
"В балансе" находится между осторожностью и свободой в ответах.`,
      temperature: 'Температура',
      temperatureMessage: 'Температура обязательна',
      temperatureTip:
        'Этот параметр контролирует случайность предсказаний модели. Низкая температура делает модель более уверенной в своих ответах, в то время как высокая температура делает её более креативной и разнообразной.',
      topP: 'Топ P',
      topPMessage: 'Топ P обязательна',
      topPTip:
        'Также известный как «выбор по ядру» (nucleus sampling),метод параметризации, используемый для управления случайностью и разнообразием в генерируемых языковыми моделями ответах. Вместе с параметром температуры, Top-p позволяет более точно контролировать процесс генерации текста, делая его либо более креативным, либо более предсказуемым.',
      presencePenalty: 'Штраф за присутствие',
      presencePenaltyMessage: 'Штраф за присутствие обязателен',
      presencePenaltyTip:
        'Это препятствует модели повторять ту же информацию, штрафуя слова, которые уже появились в разговоре.',
      frequencyPenalty: 'Штраф за частоту',
      frequencyPenaltyMessage: 'Штраф за частоту обязателен',
      frequencyPenaltyTip:
        'Подобно штрафу за присутствие (штраф за повторное использование слова), этот параметр снижает склонность модели часто повторять одни и те же слова.',
      maxTokens: 'Максимальное количество токенов',
      maxTokensMessage: 'Макс токены обязательны',
      maxTokensTip:
        'Этот параметр устанавливает максимальную длину вывода модели, измеряемую в количестве токенов (слов или частей слов).',
      quote: 'Показать цитату',
      quoteTip: 'Должен ли быть показан источник оригинального текста?',
      selfRag: 'Self-RAG',
      selfRagTip:
        'Пожалуйста, обратитесь к: https://huggingface.co/papers/2310.11511',
      overview: 'API чат-бота',
      pv: 'Количество сообщений',
      uv: 'Количество активных пользователей',
      speed: 'Скорость вывода токенов',
      tokens: 'Потребляемое количество токенов',
      round: 'Номер взаимодействия сессии',
      thumbUp: 'Удовлетворенность клиента',
      preview: 'Предварительный просмотр',
      embedded: 'Встроенный',
      serviceApiEndpoint: 'API конечная точка сервиса',
      apiKey: 'API ключ',
      apiReference: 'Документы API',
      dateRange: 'Диапазон дат:',
      backendServiceApi: 'API бэкенд сервиса',
      createNewKey: 'Создать новый ключ',
      created: 'Создано',
      action: 'Действие',
      embedModalTitle: 'Встраивание на сайт',
      comingSoon: 'Скоро',
      fullScreenTitle: 'Полное встраивание',
      fullScreenDescription:
        'Встроите следующий iframe в ваш сайт в желаемом месте',
      partialTitle: 'Частичное встраивание',
      extensionTitle: 'Расширение для Chrome',
      tokenError: 'Сначала создайте токен API!',
      searching: 'поиск...',
      parsing: 'Парсинг',
      uploading: 'Загрузка',
      uploadFailed: 'Ошибка загрузки',
      regenerate: 'Регенерация',
      read: 'Читать содержимое',
      tts: 'Текст в речь',
      ttsTip:
        'Чтобы воспроизвести голос с использованием преобразования голоса, сначала выберите TTS (модель преобразования речи) в настройках.',
      relatedQuestion: 'Связанный вопрос',
      answerTitle: 'Sherlock',
      multiTurn: 'Оптимизация многократных запросов',
      multiTurnTip:
        'В многораундовых обменах сообщениями запрос к базе знаний оптимизируется, и для обработки дополнительных токенов будет вызвана крупная модель.',
      howUseId: 'Как использовать ID чата?',
      description: 'Описание Ассистента',
    },
    setting: {
      profile: 'Профиль',
      profileDescription: 'Обновите здесь свою фотографию и личные данные.',
      password: 'Пароль',
      passwordDescription:
        'Пожалуйста, введите ваш текущий пароль для изменения пароля.',
      model: 'Поставщики моделей',
      modelDescription: 'Установите параметр модели и API-ключ здесь.',
      team: 'Команда',
      system: 'Системная Инструкция',
      logout: 'Выйти',
      username: 'Имя пользователя',
      usernameMessage: 'Пожалуйста, введите ваше имя пользователя!',
      photo: 'Ваша фотография',
      photoDescription: 'Это будет отображаться в вашем профиле.',
      colorSchema: 'Тема',
      colorSchemaMessage: 'Пожалуйста, выберите вашу Тему!',
      colorSchemaPlaceholder: 'выберите вашу Тему',
      bright: 'Светлая',
      dark: 'Темная',
      timezone: 'Часовой пояс',
      timezoneMessage: 'Пожалуйста, введите ваш часовой пояс!',
      timezonePlaceholder: 'выберите ваш часовой пояс',
      email: 'Адрес электронной почты',
      emailDescription:
        'После регистрации адрес электронной почты не может быть изменен.',
      currentPassword: 'Текущий пароль',
      currentPasswordMessage: 'Пожалуйста, введите ваш пароль!',
      newPassword: 'Новый пароль',
      newPasswordMessage: 'Пожалуйста, введите ваш новый пароль!',
      newPasswordDescription:
        'Ваш новый пароль должен содержать более 8 символов.',
      confirmPassword: 'Подтвердите новый пароль',
      confirmPasswordMessage: 'Пожалуйста, подтвердите ваш пароль!',
      confirmPasswordNonMatchMessage:
        'Введенные вами новые пароли не совпадают!',
      cancel: 'Отменить',
      addedModels: 'Добавленные модели',
      modelsToBeAdded: 'Модели для добавления',
      addTheModel: 'Добавить модель',
      apiKey: 'API-ключ',
      apiKeyMessage:
        'Пожалуйста, введите API-ключ (для локально развернутой модели проигнорируйте это).',
      apiKeyTip:
        'API-ключ можно получить, зарегистрировав соответствующего поставщика LLM.',
      showMoreModels: 'Показать больше моделей',
      baseUrl: 'Базовый URL',
      baseUrlTip:
        'Если ваш API-ключ от OpenAI, просто проигнорируйте это. Любые другие промежуточные поставщики предоставят этот базовый URL с API-ключом.',
      modify: 'Изменить',
      systemModelSettings: 'Настройки модели системы',
      chatModel: 'Модель чата',
      chatModelTip:
        'Модель LLM по умолчанию, которую будут использовать все новосозданные базы знаний.',
      embeddingModel: 'Модель эмбеддинга',
      embeddingModelTip:
        'Модель встраивания по умолчанию, которую будут использовать все новосозданные базы знаний.',
      img2txtModel: 'Модель Img2txt',
      img2txtModelTip:
        'Модель многофункционального модуля по умолчанию, которую будут использовать все новосозданные базы знаний. Она может описывать изображение или видео.',
      sequence2txtModel: 'Модель Sequence2txt',
      sequence2txtModelTip:
        'ASR модель по умолчанию, которую будут использовать все новосозданные базы знаний. Используйте эту модель для перевода голосов в соответствующий текст.',
      rerankModel: 'Модель переоценки',
      rerankModelTip:
        'Модель переоценки по умолчанию используется для переоценки частей, полученных по вопросам пользователей.',
      ttsModel: 'Модель TTS',
      ttsModelTip:
        'Модель TTS по умолчанию будет использоваться для генерации речи во время бесед по запросу.',
      workspace: 'Рабочая область',
      upgrade: 'Обновить',
      addLlmTitle: 'Добавить LLM',
      modelName: 'Наименование модели',
      modelID: 'ID модели',
      modelUid: 'UID модели',
      modelNameMessage: 'Пожалуйста, введите имя вашей модели!',
      modelType: 'Тип модели',
      modelTypeMessage: 'Пожалуйста, введите тип вашей модели!',
      addLlmBaseUrl: 'Базовый URL',
      baseUrlNameMessage: 'Пожалуйста, введите ваш базовый URL!',
      vision: 'Поддерживает ли это Визуализацию?',
      ollamaLink: 'Как интегрировать {{name}}',
      FishAudioLink: 'Как использовать FishAudio',
      TencentCloudLink: 'Как использовать TencentCloud ASR',
      volcModelNameMessage: 'Пожалуйста, введите имя вашей модели!',
      addEndpointID: 'EndpointID модели',
      endpointIDMessage: 'Пожалуйста, введите EndpointID вашей модели',
      addArkApiKey: 'VOLC ARK_API_KEY',
      ArkApiKeyMessage: 'Пожалуйста, введите ваш ARK_API_KEY',
      bedrockModelNameMessage: 'Пожалуйста, введите имя вашей модели!',
      addBedrockEngineAK: 'ACCESS KEY',
      bedrockAKMessage: 'Пожалуйста, введите ваш ACCESS KEY',
      addBedrockSK: 'SECRET KEY',
      bedrockSKMessage: 'Пожалуйста, введите ваш SECRET KEY',
      bedrockRegion: 'Регион AWS',
      bedrockRegionMessage: 'Пожалуйста, выберите!',
      'us-east-1': 'США Восток (Северная Виргиния)',
      'us-west-2': 'США Запад (Орегон)',
      'ap-southeast-1': 'Азиатско-Тихоокеанский регион (Сингапур)',
      'ap-northeast-1': 'Азиатско-Тихоокеанский регион (Токио)',
      'eu-central-1': 'Европа (Франкфурт)',
      'us-gov-west-1': 'AWS GovCloud (США-Запад)',
      'ap-southeast-2': 'Азиатско-Тихоокеанский регион (Сидней)',
      addHunyuanSID: 'Hunyuan Secret ID',
      HunyuanSIDMessage: 'Пожалуйста, введите ваш Secret ID',
      addHunyuanSK: 'Hunyuan Secret Key',
      HunyuanSKMessage: 'Пожалуйста, введите ваш Secret Key',
      addTencentCloudSID: 'TencentCloud Secret ID',
      TencentCloudSIDMessage: 'Пожалуйста, введите ваш Secret ID',
      addTencentCloudSK: 'TencentCloud Secret Key',
      TencentCloudSKMessage: 'Пожалуйста, введите ваш Secret Key',
      SparkModelNameMessage: 'Пожалуйста, выберите модель Spark',
      addSparkAPIPassword: 'Пароль API Spark',
      SparkAPIPasswordMessage: 'Пожалуйста, введите ваш APIPassword',
      addSparkAPPID: 'APPID Spark',
      SparkAPPIDMessage: 'Пожалуйста, введите ваш APPID',
      addSparkAPISecret: 'APISecret Spark',
      SparkAPISecretMessage: 'Пожалуйста, введите ваш APISecret',
      addSparkAPIKey: 'APIKey Spark',
      SparkAPIKeyMessage: 'Пожалуйста, введите ваш APIKey',
      yiyanModelNameMessage: 'Пожалуйста, введите имя модели',
      addyiyanAK: 'yiyan API KEY',
      yiyanAKMessage: 'Пожалуйста, введите ваш API KEY',
      addyiyanSK: 'yiyan Secret KEY',
      yiyanSKMessage: 'Пожалуйста, введите ваш Secret KEY',
      FishAudioModelNameMessage:
        'Пожалуйста, дайте имя вашей модели синтеза речи',
      addFishAudioAK: 'Fish Audio API KEY',
      addFishAudioAKMessage: 'Пожалуйста, введите ваш API KEY',
      addFishAudioRefID: 'FishAudio Референс ID',
      addFishAudioRefIDMessage:
        'Пожалуйста, введите Reference ID (оставьте пустым, чтобы использовать модель по умолчанию).',
      GoogleModelIDMessage: 'Пожалуйста, введите ваш ID модели!',
      addGoogleProjectID: 'ID проекта',
      GoogleProjectIDMessage: 'Пожалуйста, введите ваш ID проекта',
      addGoogleServiceAccountKey:
        'Ключ службы (оставьте пустым, если используете учетные данные по умолчанию)',
      GoogleServiceAccountKeyMessage:
        'Пожалуйста, введите ключ службы Google Cloud в формате base64',
      addGoogleRegion: 'Регион Google Cloud',
      GoogleRegionMessage: 'Пожалуйста, введите регион Google Cloud',
      modelProvidersWarn:
        'Пожалуйста, сначала добавьте как модель встраивания, так и LLM в <b>Настройки > Поставщики моделей</b>.',
      apiVersion: 'API-Version',
      apiVersionMessage: 'Please input API version',
      add: 'Добавить',
      updateDate: 'Дата обновления',
      role: 'Роль',
      invite: 'Пригласить',
      agree: 'Accept',
      refuse: 'Decline',
      teamMembers: 'Члены команды',
      joinedTeams: 'Мои команды',
      sureDelete: 'Are you sure to remove this member?',
      quit: 'Quit',
      sureQuit: 'Are you sure you want to quit the team you joined?',
    },
    message: {
      registered: 'Зарегистрировано!',
      logout: 'выход',
      logged: 'вошел!',
      pleaseSelectChunk: 'Пожалуйста, выберите фрагмент!',
      modified: 'Изменено',
      created: 'Создано',
      deleted: 'Удалено',
      renamed: 'Переименовано',
      operated: 'Операция выполнена',
      updated: 'Обновлено',
      uploaded: 'Загружено',
      200: 'Сервер успешно вернул запрашиваемые данные.',
      201: 'Данные успешно созданы или изменены.',
      202: 'Запрос поставлен в очередь для выполнения в фоновом режиме (асинхронная задача).',
      204: 'Данные успешно удалены.',
      400: 'В запросе была ошибка, и сервер не создал или не изменил данные.',
      401: 'У пользователя нет прав (неверный токен, имя пользователя, пароль).',
      403: 'Пользователь авторизован, но доступ запрещен.',
      404: 'Запрос был сделан к записи, которая не существует, и сервер не выполнил операцию.',
      406: 'Запрашиваемый формат недоступен.',
      410: 'Запрашиваемый ресурс был permanently удален и больше не будет доступен.',
      422: 'При создании объекта произошла ошибка валидации.',
      500: 'Произошла ошибка сервера, пожалуйста, проверьте сервер.',
      502: 'Ошибка шлюза.',
      503: 'Сервис недоступен, и сервер временно перегружен или находится на техническом обслуживании.',
      504: 'Таймаут шлюза.',
      requestError: 'Ошибка запроса',
      networkAnomalyDescription:
        'В вашей сети обнаружена аномалия, и вы не можете подключиться к серверу.',
      networkAnomaly: 'сетевая аномалия',
      hint: 'подсказка',
    },
    fileManager: {
      name: 'Наименование',
      uploadDate: 'Дата загрузки',
      knowledgeBase: 'База знаний',
      size: 'Размер',
      action: 'Действие',
      addToKnowledge: 'Ссылка на базу знаний',
      pleaseSelect: 'Пожалуйста, выберите',
      newFolder: 'Новая папка',
      file: 'Файл',
      uploadFile: 'Загрузить файл',
      directory: 'Каталог',
      uploadTitle: 'Нажмите или перетащите файл в эту область для загрузки',
      uploadDescription:
        'Поддержка загрузки одного или нескольких файлов. Строго запрещено загружать данные компании или другие запрещенные файлы.',
      local: 'Локальные загрузки',
      s3: 'Загрузки S3',
      preview: 'Предварительный просмотр',
      fileError: 'Ошибка файла',
      uploadLimit:
        'Размер файла не может превышать 10 МБ, а общее количество файлов не может превышать 128',
      destinationFolder: 'Папка назначения',
    },
    flow: {
      cite: 'Цитата',
      citeTip: 'Совет по цитированию',
      name: 'Имя',
      nameMessage: 'Пожалуйста, введите имя',
      description: 'Описание',
      examples: 'Примеры',
      to: 'К',
      msg: 'Сообщения',
      messagePlaceholder: 'сообщение',
      messageMsg: 'Пожалуйста, введите сообщение или удалите это поле.',
      addField: 'Добавить поле',
      addMessage: 'Добавить сообщение',
      loop: 'Цикл',
      loopTip:
        'Цикл - это верхний предел количества итераций текущего компонента. Когда количество итераций превышает значение цикла, это означает, что компонент не может завершить текущую задачу, пожалуйста, оптимизируйте агента снова.',
      yes: 'Да',
      no: 'Нет',
      key: 'Ключ',
      componentId: 'ID компонента',
      add: 'Добавить',
      operation: 'Операция',
      run: 'Запустить',
      save: 'Сохранить',
      title: 'ID:',
      beginDescription: 'Здесь начинается поток работы.',
      answerDescription:
        'Компонент, который служит интерфейсом между пользователем и ботом, принимает ввод от пользователя и отображает ответы агента.',
      retrievalDescription:
        'Компонент, который извлекает информацию из указанной базы знаний и возвращает "Пустой ответ", если информация не найдена. Убедитесь, что выбрана правильная база знаний.',
      generateDescription:
        'Компонент, который запрашивает LLM для создания ответов. Убедитесь, что подсказка настроена правильно.',
      categorizeDescription:
        'Компонент, который использует LLM для классификации пользовательских запросов по заранее определённым категориям. Убедитесь, что вы указали имя, описание и примеры для каждой категории, а также следующий компонент для каждой из них.',
      relevantDescription:
        'Компонент, который проверяет, соответствует ли результат предыдущего компонента последнему запросу пользователя. Укажите следующий компонент для каждого варианта результата.',
      rewriteQuestionDescription:
        'Компонент, который уточняет пользовательский запрос, если не удаётся найти релевантную информацию в базе знаний. Процесс повторяется до тех пор, пока не достигнуто заданное ограничение по количеству циклов. Входной компонент — "Релевантность", выходной — "Извлечение".',
      messageDescription:
        'Компонент, который отправляет статическое сообщение. Если указано несколько сообщений, одно из них будет выбрано случайным образом. Убедитесь, что следующий компонент — "Ответ", интерфейсная часть.',
      keywordDescription:
        'Компонент, который извлекает ключевые слова из запроса пользователя, где Top N указывает количество ключевых слов для извлечения.',
      baidu: 'Baidu',
      baiduDescription:
        'Этот компонент используется для получения результатов поиска с www.baidu.com. Обычно он служит дополнением к базам знаний. Top N указывает количество результатов поиска, которое вам нужно адаптировать.',
      duckDuckGo: 'DuckDuckGo',
      duckDuckGoDescription:
        'Компонент, который извлекает результаты поиска с duckduckgo.com, где TopN указывает количество результатов поиска. Он дополняет существующие базы знаний.',
      channel: 'Канал',
      channelTip:
        'Выполните текстовый поиск или поиск новостей по вводу компонента',
      text: 'Текст',
      news: 'Новости',
      messageHistoryWindowSize: 'Размер окна сообщений',
      messageHistoryWindowSizeTip:
        'Размер окна истории сообщений, который необходимо видеть LLM. Чем больше, тем лучше. Но будьте осторожны с максимальной длиной содержимого LLM.',
      wikipedia: 'Wikipedia',
      pubMed: 'PubMed',
      email: 'Адрес электронной почты',
      emailTip:
        'Этот компонент используется для получения результатов поиска с https://pubmed.ncbi.nlm.nih.gov/. Обычно он служит дополнением к базам знаний. Электронная почта является обязательным полем.',
      arXiv: 'ArXiv',
      arXivTip:
        'Этот компонент используется для получения результатов поиска с https://arxiv.org/. Обычно он служит дополнением к базам знаний. Top N указывает количество результатов поиска, которое вам нужно адаптировать.',
      sortBy: 'Сортировать по',
      submittedDate: 'Дата подачи',
      lastUpdatedDate: 'Дата последнего обновления',
      relevance: 'Актуальность',
      google: 'Google',
      googleTip:
        'Этот компонент используется для получения результатов поиска с https://www.google.com/. Обычно он служит дополнением к базам знаний. Top N и ключ API SerpApi указывают количество результатов поиска, которое вам нужно адаптировать.',
      bing: 'Bing',
      bingTip:
        'Этот компонент используется для получения результатов поиска с https://www.bing.com/. Обычно он служит дополнением к базам знаний. Top N и ключ подписки Bing указывают количество результатов поиска, которое вам нужно адаптировать.',
      apiKey: 'API ключ',
      country: 'Страна',
      language: 'Язык',
      googleScholar: 'Google Scholar',
      googleScholarDescription:
        'Этот компонент используется для получения результатов поиска с https://scholar.google.com/. Обычно он служит дополнением к базам знаний. Top N указывает количество результатов поиска, которое вам нужно адаптировать.',
      yearLow: 'Минимальный год',
      yearHigh: 'Максимальный год',
      patents: 'Патенты',
      data: 'Данные',
      deepL: 'DeepL',
      deepLDescription:
        'Этот компонент используется для получения переводов с https://www.deepl.com/. Обычно он предоставляет более специализированный перевод.',
      authKey: 'Ключ авторизации',
      sourceLang: 'Исходный язык',
      targetLang: 'Целевой язык',
      gitHub: 'GitHub',
      githubDescription:
        'Этот компонент используется для поиска в репозитории с https://github.com/. Top N указывает количество результатов поиска, которые необходимо адаптировать.',
      baiduFanyi: 'BaiduFanyi',
      baiduFanyiDescription:
        'Этот компонент используется для получения переводов с https://fanyi.baidu.com/. Обычно он предоставляет более специализированный перевод.',
      appid: 'ID приложения',
      secretKey: 'Секретный ключ',
      domain: 'Домен',
      transType: 'Тип перевода',
      baiduSecretKeyOptions: {
        translate: 'Общий перевод',
        fieldtranslate: 'Перевод полей',
      },
      baiduDomainOptions: {
        it: 'Информационные технологии',
        finance: 'Финансовая и экономическая сфера',
        machinery: 'Производство машин',
        senimed: 'Биомедицина',
        novel: 'Онлайн литература',
        academic: 'Академические статьи',
        aerospace: 'Авиакосмическая отрасль',
        wiki: 'Гуманитарные и социальные науки',
        news: 'Новости и информация',
        law: 'Законы и нормативные акты',
        contract: 'Контракт',
      },
      baiduSourceLangOptions: {
        auto: 'Автоопределение',
        zh: 'Китайский',
        en: 'Английский',
        yue: 'Кантонский',
        wyw: 'Классический китайский',
        jp: 'Японский',
        kor: 'Корейский',
        fra: 'Французский',
        spa: 'Испанский',
        th: 'Тайский',
        ara: 'Арабский',
        ru: 'Русский',
        pt: 'Португальский',
        de: 'Немецкий',
        it: 'Итальянский',
        el: 'Греческий',
        nl: 'Голландский',
        pl: 'Польский',
        bul: 'Болгарский',
        est: 'Эстонский',
        dan: 'Датский',
        fin: 'Финский',
        cs: 'Чешский',
        rom: 'Румынский',
        slo: 'Словенский',
        swe: 'Шведский',
        hu: 'Венгерский',
        cht: 'Традиционный китайский',
        vie: 'Вьетнамский',
      },
      qWeather: 'QWeather',
      qWeatherDescription:
        'Этот компонент используется для получения информации о погоде с https://www.qweather.com/. Вы можете получить данные о погоде, индексах, качестве воздуха.',
      lang: 'Язык',
      type: 'Тип',
      webApiKey: 'Ключ Web API',
      userType: 'Тип пользователя',
      timePeriod: 'Период времени',
      qWeatherLangOptions: {
        zh: 'Упрощенный китайский',
        'zh-hant': 'Традиционный китайский',
        en: 'Английский',
        de: 'Немецкий',
        es: 'Испанский',
        fr: 'Французский',
        it: 'Итальянский',
        ja: 'Японский',
        ko: 'Корейский',
        ru: 'Русский',
        hi: 'Хинди',
        th: 'Тайский',
        ar: 'Арабский',
        pt: 'Португальский',
        bn: 'Бенгальский',
        ms: 'Малайский',
        nl: 'Голландский',
        el: 'Греческий',
        la: 'Латинский',
        sv: 'Шведский',
        id: 'Индонезийский',
        pl: 'Польский',
        tr: 'Турецкий',
        cs: 'Чешский',
        et: 'Эстонский',
        vi: 'Вьетнамский',
        fil: 'Филиппинский',
        fi: 'Финский',
        he: 'Иврит',
        is: 'Исландский',
        nb: 'Норвежский',
      },
      qWeatherTypeOptions: {
        weather: 'Прогноз погоды',
        indices: 'Индекс погоды',
        airquality: 'Качество воздуха',
      },
      qWeatherUserTypeOptions: {
        free: 'Бесплатный подписчик',
        paid: 'Платный подписчик',
      },
      qWeatherTimePeriodOptions: {
        now: 'Сейчас',
        '3d': '3 дня',
        '7d': '7 дней',
        '10d': '10 дней',
        '15d': '12 дней',
        '30d': '30 дней',
      },
      publish: 'API',
      exeSQL: 'ExeSQL',
      exeSQLDescription:
        'Компонент запрашивает результаты из соответствующей реляционной базы данных с помощью SQL-запросов. Поддерживает MySQL, PostgreSQL, MariaDB.',
      dbType: 'Тип базы данных',
      database: 'База данных',
      username: 'Имя пользователя',
      host: 'Хост',
      port: 'Порт',
      password: 'Пароль',
      switch: 'Переключатель',
      logicalOperator: 'Логический оператор',
      switchOperatorOptions: {
        equal: 'равно',
        notEqual: 'не равно',
        gt: 'Больше чем',
        ge: 'Больше или равно',
        lt: 'Меньше чем',
        le: 'Меньше или равно',
        contains: 'Содержит',
        notContains: 'Не содержит',
        startWith: 'Начинается с',
        endWith: 'Заканчивается на',
        empty: 'Пусто',
        notEmpty: 'Не пусто',
      },
      switchLogicOperatorOptions: {
        and: 'И',
        or: 'Или',
      },
      operator: 'Оператор',
      value: 'Значение',
      useTemplate: 'Использовать этот шаблон',
      wenCai: 'WenCai',
      queryType: 'Тип запроса',
      wenCaiDescription:
        'Компонент может быть использован для получения информации по широкому спектру финансовых областей, включая, но не ограничиваясь акциями, фондами и т. д.',
      wenCaiQueryTypeOptions: {
        stock: 'акции',
        zhishu: 'индекс',
        fund: 'фонд',
        hkstock: 'Гонконгские акции',
        usstock: 'Американский рынок акций',
        threeboard: 'Новый внебиржевой рынок',
        conbond: 'Конвертируемые облигации',
        insurance: 'страхование',
        futures: 'фьючерсы',
        lccp: 'Финансирование',
        foreign_exchange: 'Иностранная валюта',
      },
      akShare: 'AkShare',
      akShareDescription:
        'Этот компонент может быть использован для получения новостной информации о соответствующих акциях с сайта Eastmoney.',
      yahooFinance: 'YahooFinance',
      yahooFinanceDescription:
        'Компонент запрашивает информацию о компании на основе предоставленного символа тикера.',
      info: 'Информация',
      history: 'История',
      financials: 'Финансовые показатели',
      balanceSheet: 'Балансовый отчет',
      cashFlowStatement: 'Отчет о движении денежных средств',
      jin10: 'Jin10',
      jin10Description:
        'Этот компонент может быть использован для доступа к информации в финансовом секторе с платформы Jin10, включая быстрые новости, календарь, котировки, справки.',
      flashType: 'Тип новостей',
      filter: 'Фильтр',
      contain: 'Содержит',
      calendarType: 'Тип календаря',
      calendarDatashape: 'Форма данных календаря',
      symbolsDatatype: 'Тип данных символов',
      symbolsType: 'Тип символов',
      jin10TypeOptions: {
        flash: 'Быстрые новости',
        calendar: 'Календарь',
        symbols: 'котировки',
        news: 'справки',
      },
      jin10FlashTypeOptions: {
        '1': 'Рыночные новости',
        '2': 'Новости фьючерсов',
        '3': 'Новости США-Гонконг',
        '4': 'Новости A-акций',
        '5': 'Новости товарных рынков и валют',
      },
      jin10CalendarTypeOptions: {
        cj: 'Календарь макроэкономических данных',
        qh: 'Календарь фьючерсов',
        hk: 'Календарь гонконгского фондового рынка',
        us: 'Календарь американского фондового рынка',
      },
      jin10CalendarDatashapeOptions: {
        data: 'Данные',
        event: 'Событие',
        holiday: 'Праздник',
      },
      jin10SymbolsTypeOptions: {
        GOODS: 'Котировки товаров',
        FOREX: 'Котировки валют',
        FUTURE: 'Котировки международного рынка',
        CRYPTO: 'Котировки криптовалют',
      },
      jin10SymbolsDatatypeOptions: {
        symbols: 'Список товаров',
        quotes: 'Последние рыночные котировки',
      },
      concentrator: 'Концентратор',
      concentratorDescription:
        'Компонент, который принимает результаты от предыдущего компонента и передаёт их в качестве входных данных для следующих компонентов.',
      createGraph: 'Создать агента',
      createFromNothing: 'Создайте вашего агента с нуля',
      begin: 'Начать',
      input: 'Вводные данные',
      optional: 'Необязательно',
      addItem: 'Добавить элемент',
      generate: 'Генерация',
      output: 'Выходные данные',
      answer: 'Взаимодействие',
      categorize: 'Категоризация',
      message: 'Сообщение',
      relevant: 'Релевантность',
      rewriteQuestion: 'Переписывание',
      rewrite: 'Переписывание',
      keyword: 'Ключевые слова',
      keywordExtract: 'Ключевые слова',
      keywordExtractDescription:
        'Компонент, который извлекает ключевые слова из пользовательского запроса, с параметром Top N, определяющим количество извлекаемых слов.',
      switchDescription:
        'Компонент, который оценивает условия на основе результата предыдущих компонентов и направляет поток выполнения. Позволяет реализовать сложную логику ветвления, задавая условия и действия для каждого случая или действий по умолчанию.',
      addCase: '+ Добавить случай',
      addCondition: '+ Добавить условие',
      template: 'Шаблон',
      templateDescription:
        'Компонент, используемый для форматирования выводов различных компонентов.',
      blank: 'Пустой',
      note: 'Заметка',
      noteDescription: 'Заметка',
      notePlaceholder: 'Пожалуйста, напишите Заметку',
      operationResults: 'Результаты операции',
      retrieval: 'Извлечения',
      reference: 'Ссылка',
      promptText:
        'Пожалуйста, выполните суммаризацию следующих абзацев. Будьте внимательны с числами, не придумывайте данные. Абзацы приведены ниже: {input} Это содержание, которое нужно суммировать.',
      createFromTemplates: 'Создать из шаблонов',
      content: 'Содержание',
      arXivDescription:
        'A component that searches from https://arxiv.org/, allowing you to specify the number of search results using TopN. It supplements the existing knowledge bases.',
      pubMedDescription:
        'A component that searches from https://pubmed.ncbi.nlm.nih.gov/, allowing you to specify the number of search results using TopN. It supplements the existing knowledge bases.',
      wikipediaDescription: `A component that searches from wikipedia.org, using TopN to specify the number of search results. It supplements the existing knowledge bases.`,
      googleDescription:
        'A component that searches from https://www.google.com/, allowing you to specify the number of search results using TopN. It supplements the existing knowledge bases. Please note that this requires an API key from serpapi.com.',
      bingDescription:
        'A component that searches from https://www.bing.com/, allowing you to specify the number of search results using TopN. It supplements the existing knowledge bases. Please note that this requires an API key from microsoft.com.',
      howUseId: 'Как использовать ID агента?',
    },
    footer: {
      profile: 'Все права защищены @ React',
    },
    layout: {
      file: 'файл',
      knowledge: 'знания',
      chat: 'чат',
    },
  },
};
